import React, { memo } from 'react';
import './CartModal.scss';
import PropTypes from 'prop-types';
import Modal from '@components/feedback/Modal/Modal';
import { connect } from 'react-redux';
import mova from 'mova';
import DishCard from '@components/display/DishCard/DishCard';
import { getOrderAmount, getPlaceOrders } from '@utils/menu/menu';
import Button from '@components/inputs/Button/Button';
import { clearOrder } from '@data/store/actions/menu';

const t = mova.ns('containers.CartModal');

const CartModal = ({ place, clearOrder }) => {
  const placeOrders = getPlaceOrders();
  const dishes = place?.dishes || [];
  const dishCards = placeOrders.map(o => <DishCard key={o.id} dish={dishes.find(d => d.id === o.id)} short />);
  const amount = getOrderAmount();

  return (
    <Modal className='cart-modal' title={t('title')}>
      {dishCards.length === 0 && (
        <div className='cart-modal__empty'>{t('empty')}</div>
      )}
      {dishCards.length > 0 && (
        <div>
          {dishCards}
          <div className='cart-modal__total'>
            <div>{t('total')}</div>
            <div>{amount} {t('uah')}</div>
          </div>
          <div className='cart-modal__clear'>
            <Button onClick={clearOrder} fullWidth size='medium'>
              {t('clear')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

CartModal.propTypes = {
  place: PropTypes.object.isRequired,
  clearOrder: PropTypes.func.isRequired,
};

const mapStateToProps = ({ place }) => ({
  place: place.details.data,
  orders: place.menu, // to refresh in case of changes
});

const mapDispatchToProps = {
  clearOrder,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(CartModal));
