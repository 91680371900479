import React from 'react';
import Colors from '@data/enums/Color.enum';

export function heart({ fill, filled, size = 24 }) {
  return (
    <svg viewBox='0 0 24 24' width={size} height={size} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.6328 6.64714C21.3187 5.91972 20.8657 5.26055 20.2992 4.70651C19.7323 4.15082 19.064 3.70922 18.3305 3.40573C17.5699 3.08978 16.7541 2.92805 15.9305 2.92995C14.775 2.92995 13.6477 3.24635 12.668 3.84401C12.4336 3.98698 12.2109 4.14401 12 4.3151C11.7891 4.14401 11.5664 3.98698 11.332 3.84401C10.3523 3.24635 9.225 2.92995 8.06953 2.92995C7.2375 2.92995 6.43125 3.08932 5.66953 3.40573C4.93359 3.71042 4.27031 4.1487 3.70078 4.70651C3.13359 5.25992 2.6805 5.91925 2.36719 6.64714C2.04141 7.40417 1.875 8.20807 1.875 9.03542C1.875 9.81589 2.03438 10.6292 2.35078 11.4565C2.61563 12.1479 2.99531 12.8651 3.48047 13.5893C4.24922 14.7354 5.30625 15.9307 6.61875 17.1424C8.79375 19.151 10.9477 20.5385 11.0391 20.5948L11.5945 20.951C11.8406 21.1081 12.157 21.1081 12.4031 20.951L12.9586 20.5948C13.05 20.5362 15.2016 19.151 17.3789 17.1424C18.6914 15.9307 19.7484 14.7354 20.5172 13.5893C21.0023 12.8651 21.3844 12.1479 21.6469 11.4565C21.9633 10.6292 22.1227 9.81589 22.1227 9.03542C22.125 8.20807 21.9586 7.40417 21.6328 6.64714ZM12 19.0971C12 19.0971 3.65625 13.751 3.65625 9.03542C3.65625 6.64714 5.63203 4.7112 8.06953 4.7112C9.78281 4.7112 11.2687 5.66745 12 7.06432C12.7313 5.66745 14.2172 4.7112 15.9305 4.7112C18.368 4.7112 20.3438 6.64714 20.3438 9.03542C20.3438 13.751 12 19.0971 12 19.0971Z'
        fill={fill || Colors.TEXT_DEFAULT}
        className='fill'
      />
      <path
        d='M21.6328 6.64714C21.3187 5.91972 20.8657 5.26055 20.2992 4.70651C19.7323 4.15082 19.064 3.70922 18.3305 3.40573C17.5699 3.08978 16.7541 2.92805 15.9305 2.92995C14.775 2.92995 13.6477 3.24635 12.668 3.84401C12.4336 3.98698 12.2109 4.14401 12 4.3151C11.7891 4.14401 11.5664 3.98698 11.332 3.84401C10.3523 3.24635 9.225 2.92995 8.06953 2.92995C7.2375 2.92995 6.43125 3.08932 5.66953 3.40573C4.93359 3.71042 4.27031 4.1487 3.70078 4.70651C3.13359 5.25992 2.6805 5.91925 2.36719 6.64714C2.04141 7.40417 1.875 8.20807 1.875 9.03542C1.875 9.81588 2.03438 10.6292 2.35078 11.4565C2.61563 12.1479 2.99531 12.8651 3.48047 13.5893C4.24922 14.7354 5.30625 15.9307 6.61875 17.1424C8.79375 19.151 10.9477 20.5385 11.0391 20.5948L11.5945 20.951C11.8406 21.1081 12.157 21.1081 12.4031 20.951L12.9586 20.5948C13.05 20.5362 15.2016 19.151 17.3789 17.1424C18.6914 15.9307 19.7484 14.7354 20.5172 13.5893C21.0023 12.8651 21.3844 12.1479 21.6469 11.4565C21.9633 10.6292 22.1227 9.81588 22.1227 9.03542C22.125 8.20807 21.9586 7.40417 21.6328 6.64714Z'
        fill={filled ? Colors.TEXT_BLACK : 'none'}
      />
    </svg>
  );
}
