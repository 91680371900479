import React, { useRef } from 'react';
import SearchBar from '@containers/SearchBar/SearchBar';
import './SearchPage.scss';
import QueryResult from '@components/display/QueryResult/QueryResult';
import PlaceCard from '@components/display/PlaceCard/PlaceCard';
// import SortResults from '@containers/SortResults/SortResults.container';
import PropTypes from 'prop-types';
import Loading from '@components/feedback/Loading/Loading';
import { connect } from 'react-redux';
import Div100vh from 'react-div-100vh';
import {
  GPT_PROMO_HEIGHT,
  HEADER_HEIGHT,
  MAP_MOBILE_VIEW_WIDTH,
  MOBILE_VIEW_WIDTH,
  SUMMARY_HEIGHT
} from '@utils/constants';
import { VIEW_MAP } from '@constants/app';
import useMedia from '@utils/hooks/useMedia';
import mova from 'mova';
import { withRouter } from 'react-router-dom';
import Map from '@components/display/Map/Map';
import queryString from 'query-string';
import useAsyncState from '@utils/hooks/useAsyncState';
import ChangeCityPopup from '@containers/popup/ChangeCityPopup/ChangeCityPopup';
import { getCityFromUrl } from '@utils/url/urlUtils';
import Pagination from '../../components/display/Pagination/Pagination';
import InfoTags from '../../components/display/InfoTags';
import MainPageSearchInput from '../../containers/MainPageSearchInput/MainPageSearchInput';

const t = mova.ns('pages.Search');

function SearchPage({ mapIsOpen, places, searchLoading, location }) {
  const [page, setPage] = useAsyncState(0);
  const mapRef = useRef(null);
  const showMobileVersion = useMedia([`(max-width: ${MOBILE_VIEW_WIDTH})`], [true], false);
  const showMapMobileVersion = useMedia([`(max-width: ${MAP_MOBILE_VIEW_WIDTH})`], [true], false);
  const mobileView = (mapIsOpen && showMapMobileVersion) || showMobileVersion;
  const showInlineCards = useMedia([`(max-width: ${MAP_MOBILE_VIEW_WIDTH})`], [true], false) || mapIsOpen;

  const queryParams = queryString.parse(location.search);

  const SHOW_PER_PAGE = 20;
  const slicedPlaces = (places || []).slice(page * SHOW_PER_PAGE, SHOW_PER_PAGE + page * SHOW_PER_PAGE);
  const currentCity = getCityFromUrl();

  const placeCards = slicedPlaces.map(place => (
    <PlaceCard
      place={place}
      key={place.id}
      inline={showInlineCards}
      sortTimeFrom={queryParams.time ? new Date(queryParams.time) : undefined}
      mapRef={mapRef}
    />
  ));

  const noSearchResults = !searchLoading && placeCards.length === 0;

  return (
    <div
      className={`search-page ${mobileView && 'search-page--mobile'} ${mapIsOpen && 'search-page--map-open'}`}
      style={{ minHeight: `calc(100rvh - ${HEADER_HEIGHT} - ${currentCity === 'lviv' && mobileView ? GPT_PROMO_HEIGHT : 0}` }}
    >
      <div className='search-page__top'>
        <div>
          <div className='search-page__top-label'>{t('topLabel')}</div>
          <MainPageSearchInput mapRef={mapRef} setPage={setPage} />
        </div>
      </div>
      <div className='search-page__content content-container'>
        <div className='search-page__left'>
          <div className='search-page__filters'>
            <div className='search-page__filters-content'>
              <SearchBar className='row search-page__search-bar' mapRef={mapRef} setPage={setPage} />
              {places.length > 0 && (
                <div className='row search-page__tags-row row--display-none'>
                  <div className='search-page__query-and-tags'>
                    <QueryResult results={places.length} className='search-page__query-result' />
                    {!mapIsOpen && <InfoTags tags={[]} rows={1} checked className='search-page__found-tags' />}
                  </div>
                  {/*
                <SortResults
                  className='search-page__sort'
                />
                */}
                </div>
              )}
            </div>
          </div>
          <div className={`search-page__results ${mapIsOpen && 'search-page__results--inline'}`}>
            {placeCards}
            {places.length > SHOW_PER_PAGE && (
              <Pagination
                pageCount={Math.ceil(places.length / SHOW_PER_PAGE)}
                listSize={places.length}
                onChange={setPage}
                currentPage={page}
              />
            )}
            {searchLoading && <Loading inline className='sarch-page__loading-more' />}
            {noSearchResults && <div className='search-page__no-results'>{t('noResults')}</div>}
          </div>
        </div>
        {
          mapIsOpen && !showMapMobileVersion && (
            <Div100vh
              style={{ height: `calc(100rvh - ${HEADER_HEIGHT} - ${SUMMARY_HEIGHT})` }}
              className='search-page__map'
            >
              <Map places={places} fullHeight ref={mapRef} />
            </Div100vh>
          )
        }
      </div>
      <ChangeCityPopup />
    </div>
  );
}

SearchPage.propTypes = {
  places: PropTypes.array,
  mapIsOpen: PropTypes.bool.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

SearchPage.defaultProps = {
  places: []
};

const mapStateToProps = ({ app, search }) => ({
  mapIsOpen: app.view === VIEW_MAP,
  places: search.data || [],
  searchLoading: search.isLoading,
});

export default connect(mapStateToProps)(withRouter(SearchPage));
