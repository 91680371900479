import React, { memo } from 'react';
import { isDefined } from '@utils/lo/lo';
import PropTypes from 'prop-types';

const CloudinaryImage = ({ src, width, height, alt, style = {}, ...rest }) => {
  const imageUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload/${isDefined(width) ? `w_${width * 2},` : ''}${isDefined(height) ? `h_${height * 2},` : ''}c_fill/${src}`;

  return (
    <img
      alt={alt}
      style={{
        width: isDefined(width) ? `${width}px` : 'unset',
        height: isDefined(height) ? `${height}px` : 'unset',
        objectFit: 'cover',
        ...style,
      }}
      src={imageUrl}
      {...rest}
    />
  );
};

CloudinaryImage.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  style: PropTypes.object,
};

export default memo(CloudinaryImage);
