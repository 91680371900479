import React, { memo } from 'react';
import './Button.scss';
import PropTypes from 'prop-types';
import SvgIcon from '@components/display/SvgIcon/SvgIcon';

/**
 * Renders a clickable button
 *
 * @param onClick Callback function when button is clicked
 * @param children Text shown on the button
 * @param size Size of the button. Options: small, medium, large (default value)
 * @param type Define color scheme of the button. Options:
 *  - default (default value) - Brand green color
 *  - danger - Red color
 *  - flat - Not filled button
 * @param disabled Allows to disable a button
 * @param icon Icon to be shown before the text
 * @param iconProps Props passed to the Icon component
 * @param className Class to be assigned to a wrapper component
 * @param inputClass Class to be assigned to a <button> input
 * @param fullWidth If true, rendered button will take full width of parent container
 * @param behavior Property of the native <button> "type" attribute
 * @return React component
 */
function Button({ onClick, size, type, disabled, children, icon, iconProps, className, inputClass, fullWidth, behavior, fontSize = 14 }) {
  return (
    <label className={`input-group-item__wrapper ${className}`} style={{ fontSize }}>
      <button
        type={behavior}
        className={`input-group-item button button--${size} button--${type} ${inputClass} ${fullWidth && 'button--full-width'}`}
        onClick={onClick}
        disabled={disabled}
        style={{ fontSize }}
      >
        {icon && <SvgIcon className='button__icon' type={icon} {...iconProps} />}
        {children}
      </button>
    </label>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.node
  ]),
  size: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconProps: PropTypes.object,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  behavior: PropTypes.string
};

Button.defaultProps = {
  onClick: undefined,
  size: 'large', // small, medium, large
  type: 'default', // default, flat, danger
  disabled: false,
  icon: undefined,
  iconProps: {},
  className: '',
  inputClass: '',
  fullWidth: false,
  behavior: 'button',
  children: null,
};

export default memo(Button);
