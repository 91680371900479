import React from 'react';
import PropTypes from 'prop-types';
import mova from 'mova';
import './ConfirmLegal.scss';
import TextLink from '@components/navigation/TextLink/TextLink';
import { baseUrlPath } from '@utils/url/urlUtils';

const t = mova.ns('legal.confirmation');

function ConfirmLegal(props) {
  const { className, buttonName } = props;

  return (
    <div className={`confirm-legal ${className}`}>
      {t('confirm1', { buttonName })}&nbsp;
      <TextLink type='text' to={`${baseUrlPath()}/privacy-policy`} newPage className='confirm-legal__link'>
        {t('privacyGeneric')}
      </TextLink>
      {t('confirm2')}&nbsp;
      <TextLink type='text' to={`${baseUrlPath()}/cookie-policy`} newPage className='confirm-legal__link'>
        {t('cookiesGeneric')}
      </TextLink>
      {/* {t('confirm3')} <TextLink type='text' to={`${baseUrlPath()}/policy`} newPage>{t('cookies')}</TextLink> */}
    </div>
  );
}

ConfirmLegal.propTypes = {
  buttonName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ConfirmLegal.defaultProps = {
  className: '',
};

export default ConfirmLegal;
