import store from '@data/store/Store';
import { distinctBy } from '../lo/lo';

export const getMenuCategories = () => {
  const place = store.getState().place.details?.data;

  if (place?.dishes?.length > 0) {
    return place.dishes
      .flatMap(dish => dish.menus)
      .filter(distinctBy('id'))
      .sort((d1, d2) => d1.order - d2.order);
  } else {
    return [];
  }
};

export const getCategoryDishes = (category) => {
  const place = store.getState().place.details?.data;

  if (place?.dishes?.length > 0 && category) {
    return place.dishes.filter(dish => dish.menus.find(c => c.id === category.id));
  } else {
    return [];
  }
};

export const getLikedDishes = () => {
  const place = store.getState().place.details?.data;
  const dishes = place?.dishes || [];
  return dishes.filter(d => d.isLiked);
};

export const getPlaceOrders = () => {
  const place = store.getState().place.details?.data;
  const orders = store.getState().place.menu;
  const dishes = place?.dishes || [];
  return (orders[place?.uri] || []).filter(o => dishes.find(d => d.id === o.id));
};

export const getOrderAmount = () => {
  const place = store.getState().place.details?.data;
  const dishes = place?.dishes || [];
  return getPlaceOrders()
    .map(o => {
      const dish = dishes.find(d => d.id === o.id);
      return dish.price * o.quantity;
    })
    .reduce((acc, val) => acc + val, 0);
};
